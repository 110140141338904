import type { ReactNode } from "react";

type ImageWrapperProps = {
  children: ReactNode;
};

export default function ImageWrapper({
  children,
}: ImageWrapperProps): JSX.Element {
  return (
    <div className="flex flex-col items-center py-14 px-6 lg:px-0">
      {children}
    </div>
  );
}
