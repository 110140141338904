import type { SVGProps } from "react";

export default function BusStopBaseSVG({
  ...props
}: SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 392 199" {...props}>
      <path fill="#7777A9" d="M30 72h8v113h-8z" />
      <rect width="14" height="7" x="27" y="185" fill="#AEAEE0" rx="1" />
      <mask
        id="a"
        width="8"
        height="18"
        x="30"
        y="65"
        maskUnits="userSpaceOnUse"
        style={{ maskType: "alpha" }}
      >
        <path fill="#C4C4C4" d="M30 65h8v18h-8z" />
      </mask>
      <g mask="url(#a)">
        <path
          fill="#49476F"
          d="m25.452 72.459 11.989-4.364 3.078 8.458-11.989 4.363z"
        />
      </g>
      <rect width="67" height="73" fill="#3E7FFF" rx="8" />
      <rect width="40" height="45" x="14" y="13" fill="#fff" rx="5" />
      <rect width="30" height="20" x="19" y="20" fill="#3E7FFF" rx="2" />
      <circle cx="22.5" cy="49.5" r="3.5" fill="#3E7FFF" />
      <circle cx="45.5" cy="49.5" r="3.5" fill="#3E7FFF" />
      <circle cx="22.5" cy="58.5" r="3.5" fill="#fff" />
      <circle cx="45.5" cy="58.5" r="3.5" fill="#fff" />
      <rect width="72" height="142" x="109" y="17" fill="#D9E2FF" rx="2" />
      <mask
        id="b"
        width="72"
        height="142"
        x="109"
        y="17"
        maskUnits="userSpaceOnUse"
        style={{ maskType: "alpha" }}
      >
        <rect width="72" height="142" x="109" y="17" fill="#D9E2FF" rx="2" />
      </mask>
      <g mask="url(#b)">
        <path
          fill="#BBC9EE"
          d="M89.612 149.45 187.96 23.571l19.165 14.974-98.348 125.879z"
        />
      </g>
      <mask
        id="c"
        width="72"
        height="142"
        x="109"
        y="17"
        maskUnits="userSpaceOnUse"
        style={{ maskType: "alpha" }}
      >
        <rect width="72" height="142" x="109" y="17" fill="#D9E2FF" rx="2" />
      </mask>
      <g mask="url(#c)">
        <path
          fill="#BBC9EE"
          d="m130 182.879 98.348-125.88 19.165 14.974-98.348 125.88z"
        />
      </g>
      <rect width="72" height="142" x="205" y="17" fill="#BBC9EE" rx="2" />
      <mask
        id="d"
        width="72"
        height="142"
        x="205"
        y="17"
        maskUnits="userSpaceOnUse"
        style={{ maskType: "alpha" }}
      >
        <rect width="72" height="142" x="205" y="17" fill="#BBC9EE" rx="2" />
      </mask>
      <g mask="url(#d)">
        <path
          fill="#D9E2FF"
          d="m156.613 101.45 98.348-125.88 22.55 17.62-98.347 125.878z"
        />
      </g>
      <rect width="72" height="142" x="301" y="17" fill="#BBC9EE" rx="2" />
      <mask
        id="e"
        width="72"
        height="142"
        x="301"
        y="17"
        maskUnits="userSpaceOnUse"
        style={{ maskType: "alpha" }}
      >
        <rect width="72" height="142" x="301" y="17" fill="#BBC9EE" rx="2" />
      </mask>
      <g mask="url(#e)">
        <path
          fill="#D9E2FF"
          d="m301 174.879 98.348-125.88 22.55 17.62-98.347 125.878z"
        />
      </g>
      <path
        fill="#A2AFE4"
        d="M310 79a4 4 0 0 0 4 4h45a4 4 0 0 0 4-4v-9h-53v9Z"
      />
      <rect width="53" height="45" x="310" y="33" fill="#fff" rx="4" />
      <rect width="33" height="4" x="320" y="44" fill="#3E7FFF" rx="1" />
      <rect width="33" height="4" x="320" y="54" fill="#3E7FFF" rx="1" />
      <rect width="33" height="4" x="320" y="64" fill="#3E7FFF" rx="1" />
      <rect
        width="14"
        height="7"
        fill="#7678A9"
        rx="1"
        transform="matrix(1 0 0 -1 90 192)"
      />
      <path fill="#555480" d="M92 1h10v184H92z" />
      <rect
        width="14"
        height="7"
        fill="#7678A9"
        rx="1"
        transform="matrix(1 0 0 -1 186 192)"
      />
      <path fill="#555480" d="M188 1h10v184h-10z" />
      <rect
        width="14"
        height="7"
        fill="#7678A9"
        rx="1"
        transform="matrix(1 0 0 -1 282 192)"
      />
      <path fill="#555480" d="M284 1h10v184h-10z" />
      <rect
        width="14"
        height="7"
        fill="#7678A9"
        rx="1"
        transform="matrix(1 0 0 -1 378 192)"
      />
      <path
        fill="#555480"
        d="M380 1h10v184h-10zM101 166h279v9H101zM101 1h279v9H101z"
      />
      <rect
        width="14"
        height="7"
        fill="#BDBDBD"
        rx="1"
        transform="matrix(1 0 0 -1 159 199)"
      />
      <path fill="#E5E5E5" d="M162 159h8v33h-8z" />
      <mask
        id="f"
        width="8"
        height="18"
        x="162"
        y="150"
        maskUnits="userSpaceOnUse"
        style={{ maskType: "alpha" }}
      >
        <path fill="#C4C4C4" d="M162 150h8v18h-8z" />
      </mask>
      <g mask="url(#f)">
        <path
          fill="#49476F"
          d="m157.922 156.181 12.564-2.215 1.563 8.863-12.564 2.215z"
        />
      </g>
      <rect
        width="14"
        height="7"
        fill="#BDBDBD"
        rx="1"
        transform="matrix(1 0 0 -1 309 199)"
      />
      <path fill="#E5E5E5" d="M312 159h8v33h-8z" />
      <mask
        id="g"
        width="8"
        height="18"
        x="312"
        y="150"
        maskUnits="userSpaceOnUse"
        style={{ maskType: "alpha" }}
      >
        <path fill="#C4C4C4" d="M312 150h8v18h-8z" />
      </mask>
      <g mask="url(#g)">
        <path
          fill="#49476F"
          d="m307.922 156.181 12.564-2.215 1.563 8.863-12.564 2.215z"
        />
      </g>
      <path
        fill="#E5E5E5"
        d="M143 139a1 1 0 0 1 1-1h194a1 1 0 0 1 1 1v16H143v-16Z"
      />
      <path
        fill="#BDBDBD"
        d="M143 153h196v5a1 1 0 0 1-1 1H144a1 1 0 0 1-1-1v-5Z"
      />
    </svg>
  );
}
