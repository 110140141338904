import ImageWrapper from "~/components/ImageWrapper";

import BusStopBaseSVG from "~/images/BusStopBaseSVG";

export const messageTailwindStyle = "font-medium text-2xl mt-6 text-sepia-800";

export default function DefaultDisplay() {
  return (
    <ImageWrapper>
      <BusStopBaseSVG className="max-h-32 md:max-h-48 lg:max-h-56" />
      <span className={messageTailwindStyle}>Search for bus arrival.</span>
    </ImageWrapper>
  );
}
